import { render, staticRenderFns } from "./clientFilter.vue?vue&type=template&id=631a0476&scoped=true"
import script from "./clientFilter.vue?vue&type=script&lang=js"
export * from "./clientFilter.vue?vue&type=script&lang=js"
import style1 from "./clientFilter.vue?vue&type=style&index=1&id=631a0476&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "631a0476",
  null
  
)

export default component.exports